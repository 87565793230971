<template>
  <div>
    <BaseModal
      dataClass="definition-item-modify-modal"
      size="lg"
      v-bind="$attrs"
      v-on="$listeners"
      @show-modal="clear(obj)"
    >
      <template v-slot:main>
        <div data-class="work-list" class="mx-2 my-2">
          <BaseSearchCondition
            :searchCondition="searchCondition"
            :searchConditionInfo="searchConditionInfo"
            @clear="clear(obj)"
            @search="search(obj)"
          >
          </BaseSearchCondition>
          <br />
          <!-- テーブル -->
          <BasePagingTable
            v-model="searchCondition.size"
            id="work-list-table"
            :selectMode="selectMode"
            :displayModals="displayModals"
            :fields="fields"
            :items.sync="tableItems"
            :selected-items.sync="selectedItem"
            :columnInfoPage="pagingInfo.page"
            :columnInfoSize="pagingInfo.size"
            :optionsPage="getListOptions(MASTER_CODE.LIST_PAGE, false)"
            :optionsSize="getListOptions(MASTER_CODE.LIST_SIZE, false)"
            :perPage="perPage"
            @size-changed="updateSize"
          />
        </div>
      </template>
      <template v-slot:footer="{ cancel }">
        <!-- 選択ボタン -->
        <BaseButton
          variant="primary"
          icon="check-circle"
          :columnInfo="resultControlInfo.select"
          :disabled="!isSelected"
          @click="select"
        />
        <!-- 選択解除ボタン -->
        <BaseButton
          v-if="useUnset"
          variant="primary"
          icon="times-circle"
          :columnInfo="resultControlInfo.unselect"
          @click="unselect"
        />
        <!-- 閉じるボタン -->
        <BaseButton
          variant="primary"
          icon="times-circle"
          :columnInfo="resultControlInfo.close"
          @click="cancel"
        />
      </template>
    </BaseModal>
    <!-- 郵便番号モーダル -->
    <PostalCodeModal
      :id="makeSubModalId"
      type="entry"
      :displayModals="displayModals"
      @after-close-set="afterCloseSetPostalCode"
      @after-close-unset="afterCloseUnsetPostalCode"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
  </div>
</template>

<script>
import {
  getListOptions,
  getSearchMaxCount,
  getCountPerReferencePage,
  openSubModal,
} from '@/common/Common.js'
import { search, clear } from '@/master/place/PlaceModal.js'
import { DELETED_KIND } from '@/common/const.js'

export default {
  components: {
    PostalCodeModal: () => import('@/master/postalCode/PostalCodeModal.vue'),
  },

  data() {
    return {
      /**
       * 選択された作業データリスト.
       * @type {Array}
       */
      selectedItem: [],

      /**
       * 作業データリスト.
       * @type {Array}
       */
      tableItems: [],

      /**
       * 検索パラメータ.
       * @type {Object}
       */
      searchCondition: this.defaultSearchCondition(),
      perPage: getCountPerReferencePage(),
      obj: this,
    }
  },

  props: {
    selectMode: {
      type: String,
      default: 'single',
    },
    displayModals: {
      type: String,
      // 'one'  ->'place-modal'
      // 'two'  ->'place-modal-edit'
      // 'three'->'place-modal-three'
      // 'four' ->'place-modal-four'
      default: 'one',
    },
    useUnset: {
      type: Boolean,
      default: true,
    },
    useFullPlaceKind: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    placeKindOption() {
      if (this.useFullPlaceKind) {
        return getListOptions(this.MASTER_CODE.PLACE_KIND)
      } else {
        return getListOptions(this.MASTER_CODE.PLACE_KIND_SEARCH)
      }
    },
    pagingInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'paging'
      )
    },
    searchConditionInfo() {
      const searchConditionInfo = this.$store.getters['init/getScreens'](
        this.SCREEN_ID.PLACE_MODAL_LIST
      )('search_condition')
      return {
        header: searchConditionInfo.title.label,
        clear: searchConditionInfo.clear,
        search: searchConditionInfo.search,
        page: searchConditionInfo.page,
        size: searchConditionInfo.size,
        inputComponents: [
          {
            // 場所コード
            type: 'text',
            id: 'placeCode',
            columnInfo: searchConditionInfo.place_code,
            rules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
          },
          {
            // 場所名
            type: 'text',
            id: 'placeName',
            columnInfo: searchConditionInfo.place_name,
          },
          {
            // 郵便番号
            type: 'referenceCodeName',
            codeId: 'postalCode',
            codeColumnInfo: searchConditionInfo.postal_code,
            btnColumnInfo: searchConditionInfo.sub_modal_postal,
            rules: [this.INPUT_TYPE_CHECK.ZIPNUMERIC],
            codeMaxLength: 7,
            onClickCallback: () => {
              //開かれたdisplayModalsによりサブモーダルIDも変わるように修正
              //this.openSubModal(this, 'postal-code-modal')
              this.openSubModal(this, this.makeSubModalId)
            },
          },
          {
            // 住所
            type: 'text',
            id: 'address',
            columnInfo: searchConditionInfo.address,
          },
          {
            // 電話番号
            type: 'text',
            id: 'tel',
            columnInfo: searchConditionInfo.tel,
            rules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC],
          },
          {
            // 場所区分
            type: 'select',
            id: 'placeKind',
            columnInfo: searchConditionInfo.place_kind,
            options: this.placeKindOption,
            onChangeCallback: () => {},
          },
        ],
      }
    },
    resultControlInfo() {
      return this.$store.getters['init/getScreens'](
        this.SCREEN_ID.PLACE_MODAL_LIST
      )('result_ctrl_info')
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fields() {
      return this.$store.getters['init/getFields'](
        this.SCREEN_ID.PLACE_MODAL_LIST
      )(this.DOMAIN_NAME.FIELDS)
    },

    /**
     * 単数選択しているか.
     * @return {Boolean}
     */
    isSelected() {
      return this.selectedItem.length > 0
    },

    /**
     * 郵便番号参照モーダルID作成
     */
    makeSubModalId() {
      //開かれたdisplayModalsによりサブモーダルIDも変わるように修正
      let newId = 'postal-code-modal'
      if (this.displayModals == 'two') {
        newId = 'postal-code-modal-edit'
      } else if (this.displayModals == 'three') {
        newId = 'postal-code-modal-three'
      } else if (this.displayModals == 'four') {
        newId = 'postal-code-modal-four'
      }
      return newId
    },
  },

  methods: {
    getListOptions,
    search,
    clear,
    openSubModal,

    /**
     * デフォルト検索パラメータを返します.
     * @return {Object}
     */
    defaultSearchCondition() {
      return {
        placeCode: null,
        placeName: null,
        postalCode: null,
        address: null,
        tel: null,
        placeKind: null,
        deleted: DELETED_KIND.NOT_DELETED,
        size: getSearchMaxCount(),
        screenId: this.SCREEN_ID.PLACE_MODAL_LIST,
        domainName: 'result_fields',
      }
    },

    select() {
      if (this.displayModals == 'one') {
        this.$bvModal.hide('place-modal')
      } else if (this.displayModals == 'two') {
        this.$bvModal.hide('place-modal-edit')
      } else if (this.displayModals == 'three') {
        this.$bvModal.hide('place-modal-three')
      } else if (this.displayModals == 'four') {
        this.$bvModal.hide('place-modal-four')
      }
      if (this.selectMode == 'single') {
        this.$emit('after-close-set', this.selectedItem[0])
      } else {
        this.$emit('after-close-set', this.selectedItem)
      }
    },

    unselect() {
      if (this.displayModals == 'one') {
        this.$bvModal.hide('place-modal')
      } else if (this.displayModals == 'two') {
        this.$bvModal.hide('place-modal-edit')
      } else if (this.displayModals == 'three') {
        this.$bvModal.hide('place-modal-three')
      } else if (this.displayModals == 'four') {
        this.$bvModal.hide('place-modal-four')
      }
      this.$emit('after-close-unset')
    },

    updateSize: function (size) {
      this.searchCondition.size = size
    },

    /**
     * 郵便番号マスタから取得データ設定
     */
    afterCloseSetPostalCode(selectedItem) {
      this.searchCondition.postalCode = selectedItem.postal_code
    },
    afterCloseUnsetPostalCode() {
      this.searchCondition.postalCode = null
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },
  },
}
</script>

<style scoped></style>
